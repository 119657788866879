import React from "react";
import { motion } from "framer-motion";

export default function SuccessModal({ setIsShown }) {
  const close = (e) => {
    if (e.target === e.currentTarget) setIsShown(false);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0 }}
      className="modall"
      onClick={close}
    >
      <div className="modall__inner">
        <div className="modallClose" onClick={() => setIsShown(false)}></div>
        <label className="input__outer sm">
          <h5>Your response has been logged successfully.</h5>
          <h5>
            Thank you for your submission. One of the leaders from our
            Industrial Technology Practice will be in touch shortly to follow
            up. We look forward to connecting with you.
          </h5>
        </label>
      </div>
    </motion.div>
  );
}
