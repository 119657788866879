import React from "react";

export default function Feature() {
  return (
    <section className="feature">
      <div className="auto__container">
        <div className="feature__inner">
          <div className="feature__inner-title">
            <h6 className="sup">Some features</h6>
            <h3>Why You Should Choose Landmark</h3>
          </div>
          <div className="feature__inner-row">
            <div className="featureItem">
              <div className="featureItem__icon big">
                <img src="./images/lead-gen/feature/1.png" alt="" />
              </div>
              <h5>Business Development</h5>
              <p>
                We help technology partners improve their story, expand their
                marketplace, and grow their business.
              </p>
            </div>
            <div className="featureItem">
              <div className="featureItem__icon">
                <img src="./images/lead-gen/feature/2.png" alt="" />
              </div>
              <h5>Investment Banking</h5>
              <p>
                We deliver successful transactional outcomes for banking clients
                through our curated network built on more than 25 years of
                experience.
              </p>
            </div>
            <div className="featureItem">
              <div className="featureItem__icon">
                <img src="./images/lead-gen/feature/3.png" alt="" />
              </div>
              <h5>Strategic Events</h5>
              <p>
                From our Dealmakers Summit with thousands of attendees to
                intimate and focused roundtables, our meticulously curated and
                staged events connect people who aren’t just talking about
                what’s next, they’re making it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
