import React from "react";

export default function Banner() {
  return (
    <section class="banner">
      <div class="banner__bg"></div>
      <div class="auto__container">
        <div class="banner__inner">
          <button>
            <span></span>
          </button>
          <h4>
            See our Events Showcase <br />
            in 40 Seconds...
          </h4>
        </div>
      </div>
    </section>
  );
}
