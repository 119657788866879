import React, { useEffect } from "react";
import Head from "../Base/Head";
import Magic from "./sections/Magic";
import Partner2 from "./sections/Partner2";
import Feature from "./sections/Feature";
import Service from "./sections/Service";
import Review from "./sections/Review";
import Offer from "./sections/Offer";
import Contac from "./sections/Contac";
import Copy from "./sections/Copy";

export default function LeadMagic({ setLeadPage, setChangeHead }) {
  const head = {
    title: "Lead Generation",
    description: "Description",
    url: "URL_HERE",
    image: "URL_HERE",
  };
  useEffect(() => {
    setLeadPage(true);
  }, []);
  useEffect(() => {
    setChangeHead(true);
  }, []);
  return (
    <>
     
      <div className="lead-gen-wrapper">
        <Magic />
        <Partner2 />
        <Feature />
        <Service />
        <Review />
        <Offer />
        <Contac />
        <Copy />
      </div>
    </>
  );
}
