import axios from "axios";
import { toast } from "react-hot-toast";

// this api will be forced when deployed
const API_URL_PROD = "https://betterblocks.dev:7770/api/";

// here you can choose which API to run locally (when devving)
const API_URL = "https://localhost:7770/api/";
//const API_URL = API_URL_PROD;

function getAuthToken() {
  return localStorage.getItem("token");
}

class ApiService {
  constructor() {
    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1";
    this.apiUrl = isLocalhost ? API_URL : API_URL_PROD; // force prod url when not running locally
    this.token = getAuthToken();

    // const testConnection = async () => {
    //   const connectionSuccessful = await this.ping();
    //   // switch api to prod if no local backend found
    //   if (!connectionSuccessful && this.apiUrl !== API_URL_PROD) {
    //     toast.error(
    //       `API URL has been switched to PROD (since local backend errored)`
    //     );
    //     this.apiUrl = API_URL_PROD;
    //     return;
    //   }
    // };
    // testConnection();
  }

  async ping() {
    try {
      const response = await axios.get(`${this.apiUrl}misc/ping`);
      return true;
    } catch (error) {
      return false;
    }
  }

  async get(endpoint) {
    try {
      const response = await axios.get(`${this.apiUrl}${endpoint}`, {
        headers: {
          crossdomain: true,
          Authorization: `Bearer ${this.token}`,
        },
      });
      // handle successful response
      return response.data;
    } catch (error) {
      if (error.response) {
        // handle server response with status code outside the range of 2xx
        console.log(error.response.status);
        console.log(error.response.data);
        return toast.error(`Server error`);
      } else if (error.request) {
        // handle server error without a response
        console.log(error.request);
        return toast.error(`Server error`);
      } else {
        // handle client error
        console.log("Error", error.message);
        return toast.error(`Server error`);
      }
    }
  }

  async post(endpoint, data, headers = null) {
    // console.log(`Bearer ${this.token}`);
    try {
      const headersObj = {
        crossdomain: true,
        Authorization: `Bearer ${this.token}`,
      };
      if (headers) {
        for (const field in headers) {
          if (headers.hasOwnProperty(field)) {
            headersObj[field] = headers[field];
          }
        }
      }
      const response = await axios.post(`${this.apiUrl}${endpoint}`, data, {
        headers: headersObj,
      });
      // handle successful response
      return response.data;
    } catch (error) {
      if (error.response) {
        // handle server response with status code outside the range of 2xx
        console.log(error.response.status);
        console.log(error.response.data);
        return toast.error(`Server error`);
      } else if (error.request) {
        // handle server error without a response
        console.log(error.request);
        return toast.error(`Server error`);
      } else {
        // handle client error
        console.log("Error", error.message);
        return toast.error(`Server error`);
      }
    }
  }
}

export default ApiService;
