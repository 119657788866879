import React from "react";

export default function Intro() {
  return (
    <section className="hero">
      <div className="hero__bg">
        <img src={process.env.PUBLIC_URL + "/images/hero.jpg"} alt="hero" />
      </div>
      <div className="auto__container">
        <div className="hero__inner">
          {/* <div className="hero__inner-content">
            <h1 className="hero-title">
              Join Landmark <br />
              Ventures 2024 Events
            </h1>
            <p className="hero-sub-text">
              Ranging from large summits to highly curated dinners and
              roundtables, we'll be hosting a variety of events throughout 2024
              at top industry conferences across the nation! See the full lineup
              and indicate your interest to attend or partner with us.
            </p>
            <a href="#requestForm" className="button">
              Register Your Interest
            </a>
          </div> */}
          <div className="container">
            <div className="row align-items-start align-items-lg-center">
              <div className="col-md-8 offset-xxl-1 col-xxl-6 z2">
                <article className="card-leadspace">
                  <div
                    data-aos="fade-up"
                    data-aos-delay={400}
                    className="aos-init aos-animate"
                  >
                    <h2>
                      <p className="text-white intro-text">
                        Exploring Industrial Business Transformation?
                      </p>
                      <p className="intro-text sm">
                        We’re pleased to share more info below about our
                        Industrial Technology Practice – curated by Landmark
                        Ventures, focused on digital transformation for
                        industrials.
                      </p>
                    </h2>
                  </div>
                </article>
              </div>
              <div className="col-md-4 col-xxl-5">
                <div className="bodymoving mx-auto mt-5 mt-md-0 ms-md-11 circle">
                  <div className="hero-circle">
                    <img
                      src={process.env.PUBLIC_URL + "/images/circle.png"}
                      alt="circle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
