import React from "react";
import Slider from "react-slick";
const companiesTop = [];
const companiesBottom = [];
for (let i = 1; i <= 20; i++) {
  companiesTop.push({
    id: `${i}`,
    image: `/images/sponsors/ces/${i}.png`,
  });
}
for (let i = 1; i <= 27; i++) {
  companiesBottom.push({
    id: `${20 + i}`,
    image: `/images/sponsors/events/${i}.png`,
  });
}
export default function Company() {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 0,
    pauseOnFocus: false,
    pauseOnHover: false,
    variableWidth: true,
    cssEase: "linear",
  };
  const settings2 = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 0,
    pauseOnFocus: false,
    pauseOnHover: false,
    variableWidth: true,
    cssEase: "linear",
    rtl: "rtl",
  };
  return (
    <section className="company">
      <div className="auto__container">
        <div className="company__inner">
          <h3>You're in good company</h3>
          <div className="company__inner-slider">
            <Slider {...settings}>
              {companiesTop
                .slice(companiesTop.length / 2)
                .map((item, index) => {
                  return (
                    <div className="companyItem top" key={index}>
                      <img
                        src={process.env.PUBLIC_URL + item.image}
                        alt="sponsors"
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>

          <div className="company__inner-swiper" dir="rtl">
            <Slider {...settings2}>
              {companiesBottom
                .slice(0, companiesBottom.length / 2)
                .map((item, index) => {
                  return (
                    <div className="companyItem" key={index}>
                      <img
                        src={process.env.PUBLIC_URL + item.image}
                        alt="sponsors"
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
