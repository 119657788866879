import React from "react";

export default function Offer() {
  return (
    <section className="offer">
      <div className="auto__container">
        <div className="offer__inner">
          <div className="offer__inner-title">
            <h3 className="big">
              Register Your Interest Now! Don’t Miss The{" "}
              <span>Special Offer</span>
            </h3>
            <p className="big">
              We develop lasting connections in select technology sectors to
              catalyze growth and close deals.
            </p>
            <a href="#" className="button secondary">
              SIGN UP NOW
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
