import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import { AnimatePresence } from "framer-motion";
import SuccessModal from "./SuccessModal";
import { getDisplayDateFromStartEndDateString } from "../../utils";

const tag = "industrialtech";

// const companies = [
//   {
//     id: "1",
//     value: "BlackHat",
//     date: "August 5 – 10",
//     location: "Las Vegas, NV",
//     img: "blackhat.png",
//   },
//   {
//     id: "2",
//     value: "Hubspot INBOUND",
//     date: "September 5 – 8",
//     location: "Boston, MA",
//     img: "hubspot.png",
//   },
//   {
//     id: "3",
//     value: "Dreamforce",
//     date: "September 12 – 14",
//     location: "San Francisco, CA",
//     img: "dreamforce.png",
//   },
//   {
//     id: "4",
//     value: "Groceryshop",
//     date: "September 18 – 21",
//     location: "Las Vegas, NV",
//     img: "groceryshop.png",
//   },
//   {
//     id: "5",
//     value: "HLTH",
//     date: "October 8 – 11",
//     location: "Las Vegas, NV",
//     img: "hlth.png",
//   },
//   {
//     id: "6",
//     value: "Industrial Transformation USA",
//     date: "October 10 – 12",
//     location: "Indianapolis, IN",
//     img: "industrial-trans.png",
//   },
//   {
//     id: "7",
//     value: "Money20/20",
//     date: "October 22 – 25",
//     location: "Las Vegas, NV",
//     img: "money2020.png",
//   },
//   {
//     id: "8",
//     value: "AWS RE:invent",
//     date: "November TBD",
//     location: "Las Vegas, NV",
//     img: "reinvent2023.png",
//   },
// ];
export default function Request() {
  const [isShown, setIsShown] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [events, setEvents] = useState([]);
  // companies
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const api = new ApiService();
        const response = await api.get(
          `landmark/portfolio/getCompanies/${tag}`
        );
        console.log(
          `fetchCompanies() - landmark/portfolio/getCompanies/${tag}`,
          response
        );
        if (response && response.length && response[0].companyId) {
          setCompanies(response);
        } else {
          throw "Couldn't get companies";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchCompanies();
  }, []);
  // events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const api = new ApiService();
        const response = await api.get(`landmark/events/getEvents/${tag}`);
        console.log(
          `fetchEvents() - landmark/events/getEvents/${tag}`,
          response
        );
        if (response && response.length && response[0].eventId) {
          // sort events by start date
          response.sort((a, b) => {
            return a.startDateEpoch - b.startDateEpoch;
          });
          setEvents(response);
        } else {
          throw "Couldn't get events";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchEvents();
  }, []);
  const [form, setForm] = useState({
    events: [],
    companies: [],
    briefingTopics: [],
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    notes: "",
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const topicOnChange = (e) => {
    if (e.target.checked) {
      let arr = [...form.briefingTopics];
      arr.push(e.target.id);
      updateForm({ briefingTopics: arr });
    } else {
      updateForm({
        briefingTopics: form.briefingTopics.filter(
          (item) => item !== e.target.id
        ),
      });
    }
  };

  const submitForm = async () => {
    if (!formValid) {
      console.log(`submitForm() - form is invalid`, form);
      return;
    }
    console.log(`submitForm()`, form);
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/portfolio/submitForm`, {
        form,
        tag,
      });
      console.log(`submitForm() - response`, response);
      // toast.success("Your response has been logged. Thank you!");
      setIsShown(true);
      // setCompanies(response);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("An error occurred when submitting your response.");
    }
  };

  if (!companies || !companies.length) {
    return null;
  }

  if (!events || !events.length) {
    return null;
  }

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const {
      events,
      companies,
      briefingTopics,
      firstName,
      lastName,
      company,
      jobTitle,
      email,
    } = form;
    // must select at least 1 company
    if (!companies.length && !events.length && !briefingTopics.length) {
      formErrorMsg =
        "Please select at least one event, portfolio company, or expertise topic";
      return false;
    }
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  const briefingTopicOptions = [
    "Operational AI/ML",
    "Connected Workforce",
    "Industrial Sustainability",
    "IoT & OT Cybersecurity",
    "Digital Twins & Metaverse",
  ];

  const BriefingTopicCheckbox = ({ topic }) => {
    const id = `${topic}`;
    return (
      <label htmlFor={id} className="check">
        <div className="check__box">
          <input
            type="checkbox"
            id={id}
            checked={form.briefingTopics.some((p) => p === topic)}
            onChange={topicOnChange}
          />
          <span>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
              alt="icon"
            />
          </span>
        </div>
        <div className="check-label">{topic}</div>
      </label>
    );
  };

  return (
    <>
      <section className="overview-section">
        <div className="section-header-text">
          <h4 className="title">
            Welcome to the Industrial Technology Practice at Landmark Ventures.
            We drive strategic business transformation across industrial
            operations, equipment, and services.
          </h4>
          <br />
          <h4 className="title">
            Kindly select from any of the options below that are relevant for
            you at this time, and a member of our team will follow up with you
            shortly. We look forward to our continued partnership and
            collaboration.
          </h4>
        </div>
      </section>
      <section className="topics-section">
        <div className="auto__container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="topic-container">
                <div className="title">Strategic Events</div>
                <div className="desc">
                  Where we’ve curated the most essential Industrial Technology
                  dealmaking events.
                </div>
                <img
                  className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/industrialtech/topic_pic_1.jpg"
                  }
                  alt="Strategic Events"
                />
                <a href="#events">
                  <div className="action-btn">
                    <div>Join an Event</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="topic-container">
                <div className="title">Technology Portfolio</div>
                <div className="desc">
                  Including the most innovative Industrial Technology companies
                  in our portfolio.
                </div>
                <img
                  className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/industrialtech/topic_pic_2.jpg"
                  }
                  alt="Strategic Events"
                />
                <a href="#technology">
                  <div className="action-btn">
                    <div>Request a Meeting </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="topic-container">
                <div className="title">Strategic Expertise</div>
                <div className="desc">
                  Showcasing the top insights and intelligence of our Industrial
                  Technology experts.
                </div>
                <img
                  className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/industrialtech/topic_pic_3.jpg"
                  }
                  alt="Strategic Events"
                />
                <a href="#expertise">
                  <div className="action-btn">
                    <div>Schedule a Briefing</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="request top" id="events">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading">Strategic Events</h3>
              <h5 className="request-subheading">
                If you are interested in our events, please select one or more
                below:
              </h5>
              <div className="requestForm__row">
                {events && events.length
                  ? events
                      .filter((e) => !e.hidden)
                      .map((item, index) => {
                        return (
                          <RequestItemEvent
                            itemData={item}
                            key={index}
                            updateForm={updateForm}
                            form={form}
                          />
                        );
                      })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="request" id="technology">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading">Technology Portfolio</h3>
              <h5 className="request-subheading">
                To request a meeting with one of our portfolio companies, please
                select one or more below:
              </h5>
              <div className="requestForm__row">
                {companies && companies.length
                  ? companies
                      .sort((a, b) =>
                        a.title
                          .toLowerCase()
                          .localeCompare(b.title.toLowerCase())
                      )
                      .map((item, index) => {
                        return (
                          <RequestItemCompany
                            itemData={item}
                            key={index}
                            updateForm={updateForm}
                            form={form}
                          />
                        );
                      })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="request final" id="expertise">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading">Strategic Expertise</h3>
              <h5 className="request-subheading">
                Want to schedule an Industry Trends Briefing to discuss
                innovation & transformation in the industrial technology space?
                Select all relevant topics:
              </h5>
              <div className="request-container">
                <label className="input__outer">
                  {briefingTopicOptions.map((topic, index) => (
                    <BriefingTopicCheckbox
                      key={`topic_${index}`}
                      topic={topic}
                    />
                  ))}
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="request last">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <div className="requestForm__inputs">
                <label className="input__outer sm">
                  <h5>First Name</h5>
                  <div className="input">
                    <input
                      type="name"
                      value={form.firstName}
                      onChange={onChangeInput("firstName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Last Name</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={onChangeInput("lastName")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Company</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.company}
                      onChange={onChangeInput("company")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Job title</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.jobTitle}
                      onChange={onChangeInput("jobTitle")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Email</h5>
                  <div className="input">
                    <input
                      type="email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>
                    Please share any additional notes on your personal interest
                    areas and active initiatives that may help us curate the
                    most relevant solutions for you (optional):
                  </h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.notes}
                      onChange={onChangeInput("notes")}
                    />
                  </div>
                </label>
              </div>
              <button
                type="submit"
                onClick={submitForm}
                className={`button submit-btn ${submitDisabledClass}`}
              >
                Submit
              </button>
              {formErrorMsg.length ? (
                <div className="form-invalid-msg">{formErrorMsg}</div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <AnimatePresence>
        {isShown && <SuccessModal setIsShown={setIsShown} />}
      </AnimatePresence>
    </>
  );
}

const RequestItemEvent = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.events];
      arr.push(props.itemData);
      props.updateForm({ events: arr });
      console.log(
        `RequestItemEvent() - form events updated - event added`,
        arr
      );
    } else {
      const adjustedArr = props.form.events.filter(
        (item) => item.eventId !== props.itemData.eventId
      );
      props.updateForm({
        events: adjustedArr,
      });
      console.log(
        `RequestItemEvent() - form events updated - event removed`,
        adjustedArr
      );
    }
  };

  return (
    <div className="col-12 col-sm-6">
      <div className="req-item event">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="req-item-content">
          <div className="req-item-upper">
            <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="req-item-header">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">
                {getDisplayDateFromStartEndDateString(
                  props.itemData.startDate,
                  props.itemData.endDate,
                  props.itemData.endDateEpoch
                )}
              </p>
              <p className="sm long-desc">{props.itemData.location}</p>
            </div>
          </div>
          {/* <div className="req-item-lower">
        </div> */}
        </div>
      </div>
    </div>
  );
};

const RequestItemCompany = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.companies];
      arr.push(props.itemData);
      props.updateForm({ companies: arr });
      console.log(
        `RequestItemCompany() - form companies updated - company added`,
        arr
      );
    } else {
      const adjustedArr = props.form.companies.filter(
        (item) => item.companyId !== props.itemData.companyId
      );
      props.updateForm({
        companies: adjustedArr,
      });
      console.log(
        `RequestItemCompany() - form companies updated - company removed`,
        adjustedArr
      );
    }
  };

  return (
    <div className="req-item">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <div className="req-item-content">
        <div className="req-item-upper">
          <div className="req-item-img">
            <img className="img-fluid" src={props.itemData.imageUrl} />
          </div>
          <div className="req-item-header">
            <h5>{props.itemData.title}</h5>
            <p className="sm short-desc">{props.itemData.shortDescription}</p>
            <p className="sm long-desc">{props.itemData.longDescription}</p>
          </div>
        </div>
        {/* <div className="req-item-lower">
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="requestFormItem">
      <div className="requestFormItem__inner">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="requestFormItem__content">
          <div className="d-flex event-container">
            <div className="company-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="event-info">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
              <p className="sm">{props.itemData.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
