import React from "react";

export default function Market() {
  return (
    <section className="market">
      <div className="marketTop">
        <div className="auto__container">
          <div className="marketTop__inner">
            <div className="marketTop__inner-title">
              <a href="#" className="marketTop__inner-logo">
                <img src="./images/footer-logo.png" alt="" />
              </a>
              <h1>
                Join
                <span> Landmark Ventures </span>
                2024 Events
              </h1>
              <p className="big">
                Ranging from large summits to highly curated dinners and
                roundtables, you can count on Landmark Ventures to host a
                variety of events and experiences throughout the calendar year.
                Please use the form below to indicate your interest to attend,
                partner, or host with us!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="marketMain">
        <div className="auto__container">
          <div className="marketMain__inner">
            <div className="marketMain__inner-row">
              <div className="marketMain__inner-col">
                <div className="marketItem">
                  <div className="marketItem__icon">
                    <img src="./images/lead-gen/market/eye.png" alt="" />
                  </div>
                  <h6 className="big">Business Development</h6>
                  <p>
                    We help technology partners improve their story, expand
                    their marketplace, and grow their business.
                  </p>
                </div>
                <div className="marketItem">
                  <div className="marketItem__icon">
                    <img
                      src="./images/lead-gen/market/interchange.png"
                      alt=""
                    />
                  </div>
                  <h6 className="big">Investment Banking</h6>
                  <p>
                    We deliver successful transactional outcomes for banking
                    clients through our curated network
                  </p>
                </div>
              </div>
              <div className="marketForm">
                <div className="marketForm__bg">
                  <img src="./images/lead-gen/market/poly-dots.png" alt="" />
                </div>
                <div className="marketForm__title">
                  <h4>Register Your Interest</h4>
                  <p>We'll work with you to create your perfect event</p>
                </div>
                <div className="marketForm__main">
                  <label className="input__outer">
                    <p className="sm">Your Name *</p>
                    <div className="input">
                      <input type="text" />
                    </div>
                  </label>
                  <label className="input__outer">
                    <p className="sm">Your Email *</p>
                    <div className="input">
                      <input type="email" />
                    </div>
                  </label>
                  <label className="input__outer">
                    <p className="sm">Company *</p>
                    <div className="input">
                      <input type="tel" />
                    </div>
                  </label>
                  <label className="input__outer">
                    <p className="sm">Tell us what you're looking for</p>
                    <div className="input">
                      <textarea rows="8"></textarea>
                    </div>
                  </label>
                  <button type="submit" className="button secondary">
                    SIGN UP NOW
                  </button>
                </div>
                <div className="marketForm__arrow">
                  <img src="./images/lead-gen/market/arrow-left.png" alt="" />
                </div>
                <div className="marketForm__arrow right">
                  <img src="./images/lead-gen/market/arrow-right.png" alt="" />
                </div>
              </div>
              <div className="marketMain__inner-col">
                <div className="marketItem">
                  <div className="marketItem__icon">
                    <img src="./images/lead-gen/market/cup.png" alt="" />
                  </div>
                  <h6 className="big">Strategic Events</h6>
                  <p>
                    Our meticulously curated and staged events connect people
                    who aren’t just talking about what’s next, they’re making
                    it.
                  </p>
                </div>
                <div className="marketItem">
                  <div className="marketItem__icon sm">
                    <img src="./images/lead-gen/market/lamp.png" alt="" />
                  </div>
                  <h6 className="big">Better Results</h6>
                  <p>We have unprecedented levels of client satisfaction</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
