import React, { useEffect, useState } from "react";
import {
  facebookIcon,
  flickrIcon,
  linkedIcon,
  twitterIcon,
  youtubeIcon,
} from "./SVG";
import { useLocation } from "react-router-dom";
import ApiService from "../services/ApiService";
import { toast } from "react-hot-toast";
export default function Header({ headerProps }) {
  const [menu, setMenu] = useState(false);
  const [email, setEmail] = useState("");
  const location = useLocation();

  const { sticky, transparent, cssClass } = headerProps;
  console.log(`headerProps`, headerProps);

  const start = 50;
  const onScroll = React.useCallback(() => {
    if (!sticky) {
      document.getElementById("header").classList.remove("sticky");
    }
    // sticky navbar on scroll
    else {
      if (window.scrollY > start) {
        document.getElementById("header").classList.add("sticky");
      } else {
        document.getElementById("header").classList.remove("sticky");
      }
    }
  }, []);

  const closeFunc = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };

  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);

  useEffect(() => {
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSubmit = async () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      console.log(`handleEmailSubmit() - Invalid email`);
      toast.error("Please enter a valid email");
      return false;
    }
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/events/addNewsletterEmail`, {
        email,
      });
      if (response.success) {
        toast.success("Your email has been subscribed. Thank you!");
        setEmail("");
      } else {
        throw "Backend error";
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    }
  };

  return (
    <header
      className={`header no-transition position-lt-0 ${cssClass}`}
      id="header"
    >
      <div className="auto__container">
        <div className="header__inner">
          <div className="header__inner-logo">
            <a href="https://landmarkventures.com/" style={{ display: "flex" }}>
              <img
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
            </a>
          </div>
          <div className="header__inner-side">
            <ul className="header__inner-links">
              <li>
                <a href="https://landmarkventures.com/about/">About</a>
              </li>
              <li className="dropdown">
                <a href="https://landmarkventures.com/people/">
                  people
                  <span></span>
                </a>
                <div className="dropdown__menu">
                  <a href="https://landmarkventures.com/people/">People</a>
                  <a href="https://landmarkventures.com/careers/">Careers</a>
                </div>
              </li>
              <li className="dropdown">
                <a href="https://landmarkventures.com/services/">
                  services
                  <span></span>
                </a>
                <div className="dropdown__menu">
                  <a href="https://landmarkventures.com/services/">
                    Our Services
                  </a>
                  <a href="https://landmarkventures.com/services/business-development/">
                    Business Development
                  </a>
                  <a href="https://landmarkventures.com/services/investment-banking/">
                    Investment Banking
                  </a>
                  <a href="https://landmarkventures.com/services/strategic-events/">
                    Strategic Events
                  </a>
                </div>
              </li>
              <li className="dropdown">
                <a href="https://landmarkventures.com/expertise/">
                  expertise
                  <span></span>
                </a>
                <div className="dropdown__menu">
                  <a href="https://landmarkventures.com/expertise/">
                    Expertise
                  </a>
                  <a href="https://landmarkventures.com/expertise/consumer-digital-engagement/">
                    Consumer Digital Engagement
                  </a>
                  <a href="https://landmarkventures.com/expertise/cybersecurity/">
                    Cybersecurity
                  </a>
                  <a href="https://landmarkventures.com/expertise/enterprise-it/">
                    Enterprise IT
                  </a>
                  <a href="https://landmarkventures.com/expertise/industrial-tech/">
                    Industrial Tech
                  </a>
                  <a href="https://landmarkventures.com/expertise/social-impact/">
                    Social Impact
                  </a>
                </div>
              </li>
              <li>
                <a href="https://landmarkventures.com/portfolio/">portfolio</a>
              </li>
              <li>
                <a href="https://landmarkventures.com/newsroom/">newsroom</a>
              </li>
            </ul>
            <div
              className={"burger " + (menu ? "active" : "")}
              onClick={() => setMenu(!menu)}
            >
              <span></span>
            </div>
          </div>
          <nav className={"nav " + (menu ? "active" : "")}>
            <div className="auto__container nav-side-container">
              <div className="nav__inner">
                <div className="nav__close" onClick={closeFunc}></div>
                <div className="nav__inner-row">
                  <div className="nav__inner-links">
                    <a href="https://landmarkventures.com/about/">About</a>
                    <a href="https://landmarkventures.com/people/">People</a>
                    <a href="https://landmarkventures.com/services/">
                      Services
                    </a>
                    <a href="https://landmarkventures.com/expertise/">
                      Expertise
                    </a>
                    <a href="https://landmarkventures.com/portfolio/">
                      Portfolio
                    </a>
                    <a href="https://landmarkventures.com/newsroom/">
                      Newsroom
                    </a>
                    <a href="https://landmarkventures.com/careers/">Careers</a>
                    <a href="https://landmarkventures.com/internal-resources/">
                      Employee Resources
                    </a>
                    <a href="https://landmarkventures.com/case-studies/">
                      Case Studies
                    </a>
                  </div>
                  <div className="nav__inner-social">
                    <h4 className="big">Follow Us</h4>
                    <a href="https://twitter.com/Landmark">
                      <span>{twitterIcon}</span>
                      Twitter
                    </a>
                    <a href="https://facebook.com/Landmark-Ventures-138752542808379/">
                      <span>{facebookIcon}</span>
                      Facebook
                    </a>
                    <a href="https://linkedin.com/company/landmark-ventures">
                      <span>{linkedIcon}</span>
                      Linkedin
                    </a>
                    <a href="https://youtube.com/channel/UCu-H9YnQi63vrHLtQnIA3ww">
                      <span>{youtubeIcon}</span>
                      YouTube
                    </a>
                    <a href="https://flickr.com/photos/landmarkventures/">
                      <span>{flickrIcon}</span>
                      Flickr
                    </a>
                  </div>
                  <div className="nav__inner-col">
                    {/* <a
                      href="#"
                      className="button"
                      style={{ marginBottom: "14px" }}
                    >
                      Contact Us
                    </a> */}
                    <h4 className="big">Subscribe to our newsletter</h4>
                    <p className="sm">
                      Stay connected with Landmark and up to date on the latest
                      news, trends, and ideas in our sectors.
                    </p>
                    <div className="input">
                      <input
                        type="email"
                        placeholder="youremail@mail.com"
                        value={email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="button sm"
                      onClick={handleEmailSubmit}
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="nav__lines">
              <img src={process.env.PUBLIC_URL + "/images/nav.png"} alt="nav" />
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
