import React from "react";

export default function Contac() {
  return (
    <section className="contact">
      <div className="contact__bg">
        <img src="./images/lead-gen/contact-bg.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="contact__inner">
          <div className="contact__inner-row">
            <div className="contactItem">
              <h5>Our Addresses</h5>
              <p>
                <b>New York</b>
                <br />
                475 Park Avenue South, 25th Floor <br />
                New York, NY 10016
                <br />T 212 268 8500
              </p>
              <br />
              <p>
                <b>Tel Aviv</b>
                <br />
                Beit Bonei Binyan, HaMelacha 2, 3rd Floor
                <br />
                Ra’anana, Israel 43657
                <br />T 972 73 718 1700
              </p>
            </div>
            {/* <div className="contactItem">
              <h5>Our Phones</h5>
              <p>
                <a href="tel:+2122688500">
                  <b>New York</b>: 212 268 8500
                </a>
                <a href="tel:+972737181700">
                  <b>Tel Aviv</b>: 972 73 718 1700
                </a>
              </p>
            </div> */}
            <div className="contactItem">
              <h5>contactus@landmarkventures.com</h5>
              {/* <p>
                <a href="mailto:ExplicitConcepts@Envato.com">
                  ExplicitConcepts@Envato.com
                </a>
                <a href="mailto:Morad@Market.com">Morad@Market.com</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
