import React from "react";

export default function Service() {
  return (
    <>
      <section className="service">
        <div className="auto__container">
          <div className="service__inner">
            <div className="service__inner-image">
              <img src="./images/lead-gen/service/1.png" alt="" />
            </div>
            <div className="service__inner-content">
              <h6 className="sup">Our Services</h6>
              <h3>We Provide The Solutions To Grow Your Business</h3>
              <p>Hundreds of companies have chosen to partner with Landmark.</p>
              <div className="service__inner-row">
                <div className="serviceItem">
                  <span>HOT</span>
                  <div className="serviceItem__icon">
                    <img src="./images/lead-gen/service/branding.png" alt="" />
                  </div>
                  <h6>Business</h6>
                </div>
                <div className="serviceItem">
                  <span>HOT</span>
                  <div className="serviceItem__icon sm">
                    <img src="./images/lead-gen/service/android.png" alt="" />
                  </div>
                  <h6>Investment</h6>
                </div>
                <div className="serviceItem">
                  <span>HOT</span>
                  <div className="serviceItem__icon">
                    <img
                      src="./images/lead-gen/service/developing.png"
                      alt=""
                    />
                  </div>
                  <h6>Strategy</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service light">
        <div className="auto__container">
          <div className="service__inner">
            <div className="service__inner-content">
              <h6 className="sup">Some Facts</h6>
              <h3>Experience True Business Performance Increases</h3>
              <p>
                This should be used to tell a story about your product or
                service. How can you benefit them?
              </p>
              <div className="service__inner-row">
                <div className="serviceItem">
                  <span>HOT</span>
                  <div className="serviceItem__number">
                    100
                    <sup>+</sup>
                  </div>
                  <h6>Partners</h6>
                </div>
                <div className="serviceItem">
                  <span>HOT</span>
                  <div className="serviceItem__number">
                    300
                    <sup>+</sup>
                  </div>
                  <h6>Events</h6>
                </div>
                <div className="serviceItem">
                  <span>HOT</span>
                  <div className="serviceItem__number">
                    100%
                    {/* <sup>%</sup> */}
                  </div>
                  <h6>Satisfaction</h6>
                </div>
              </div>
            </div>
            <div className="service__inner-image">
              <img src="./images/lead-gen/service/2.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
