import React from "react";

export default function Magic() {
  return (
    <section className="magic">
      <div className="magic__bg">
        <img src="./images/lead-gen/wave.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="magic__inner">
          <a href="#" className="magic__inner-logo">
            <img src="./images/lead-gen/logo-2.png" alt="" />
          </a>
          <div className="magic__inner-row">
            <div className="magic__inner-content">
              <h2>Join Landmark Ventures 2024 Events</h2>
              <p className="big">
                Ranging from large summits to highly curated dinners and
                roundtables, you can count on Landmark Ventures to host a
                variety of events and experiences throughout the calendar year.
                Please use the form below to indicate your interest to attend,
                partner, or host with us!
              </p>
              {/* <div className="magic__inner-play">
                <button>
                  <span></span>
                </button>
                <p>
                  Watch Overview <br />
                  Video in 40 Second...
                </p>
              </div> */}
            </div>
            <div className="magicForm__outer">
              <form action="" className="magicForm">
                <div className="magicForm__bg">
                  <img src="./images/lead-gen/magicForm-bg.png" alt="" />
                </div>
                <div className="magicForm__title">
                  <h4>Register Your Interest</h4>
                  <p>We'll work with you to create your perfect event</p>
                </div>
                <div className="magicForm__main">
                  <label className="input__outer">
                    <p className="sm">Your Name *</p>
                    <div className="input">
                      <input type="text" />
                    </div>
                  </label>
                  <label className="input__outer">
                    <p className="sm">Your Email *</p>
                    <div className="input">
                      <input type="email" />
                    </div>
                  </label>
                  <label className="input__outer">
                    <p className="sm">Company *</p>
                    <div className="input">
                      <input type="tel" />
                    </div>
                  </label>
                  <button type="submit" className="button primary">
                    SIGN UP NOW
                  </button>
                </div>
              </form>
              <h6>
                Join Now! Special Offer
                <b>20% Discount</b>
                <span>
                  <img src="./images/lead-gen/arrow-top.png" alt="" />
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
