import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import {
  getDisplayDateFromStartEndDateString,
  randomString,
} from "../../utils";
import { toast } from "react-hot-toast";
import { AnimatePresence } from "framer-motion";
// import SuccessModal from "./SuccessModal";
import { motion } from "framer-motion";

// const events = [
//   {
//     id: "1",
//     value: "BlackHat",
//     date: "August 5 – 10",
//     location: "Las Vegas, NV",
//     img: "blackhat.png",
//   },
//   {
//     id: "2",
//     value: "Hubspot INBOUND",
//     date: "September 5 – 8",
//     location: "Boston, MA",
//     img: "hubspot.png",
//   },
//   {
//     id: "3",
//     value: "Dreamforce",
//     date: "September 12 – 14",
//     location: "San Francisco, CA",
//     img: "dreamforce.png",
//   },
//   {
//     id: "4",
//     value: "Groceryshop",
//     date: "September 18 – 21",
//     location: "Las Vegas, NV",
//     img: "groceryshop.png",
//   },
//   {
//     id: "5",
//     value: "HLTH",
//     date: "October 8 – 11",
//     location: "Las Vegas, NV",
//     img: "hlth.png",
//   },
//   {
//     id: "6",
//     value: "Industrial Transformation USA",
//     date: "October 10 – 12",
//     location: "Indianapolis, IN",
//     img: "industrial-trans.png",
//   },
//   {
//     id: "7",
//     value: "Money20/20",
//     date: "October 22 – 25",
//     location: "Las Vegas, NV",
//     img: "money2020.png",
//   },
//   {
//     id: "8",
//     value: "AWS RE:invent",
//     date: "November TBD",
//     location: "Las Vegas, NV",
//     img: "reinvent2023.png",
//   },
// ];
export default function Request() {
  const [isShown, setIsShown] = useState(false);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const api = new ApiService();
        const tags = `events`; // separate by comma
        const response = await api.get(`landmark/events/getEvents/${tags}`);
        console.log(
          `fetchEvents() - landmark/events/getEvents/${tags}`,
          response
        );
        if (response && response.length && response[0].eventId) {
          // sort events by start date
          response.sort((a, b) => {
            return a.startDateEpoch - b.startDateEpoch;
          });
          setEvents(response);
        } else {
          throw "Couldn't get events";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchEvents();
  }, []);
  const [form, setForm] = useState({
    events: [],
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    preferredCity: "",
    referredBy: "",
    partners: [],
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const partnerOnChange = (e) => {
    if (e.target.checked) {
      let arr = [...form.partners];
      arr.push(e.target.id);
      updateForm({ partners: arr });
    } else {
      updateForm({
        partners: form.partners.filter((item) => item !== e.target.id),
      });
    }
  };

  const clearForm = () => {
    setForm({
      events: [],
      firstName: "",
      lastName: "",
      company: "",
      jobTitle: "",
      email: "",
      preferredCity: "",
      referredBy: "",
      partners: [],
    });
  };

  const submitForm = async () => {
    if (!formValid) {
      console.log(`submitForm() - form is invalid`, form);
      return;
    }
    console.log(`submitForm()`, form);
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/events/submitForm`, { form });
      console.log(`submitForm() - response`, response);
      setTimeout(() => {
        // toast.success("Your response has been logged. Thank you!");
        setIsShown(true);
        clearForm(); // clear inputs
      }, 50);
      // setEvents(response);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("An error occurred when submitting your response.");
    }
  };

  if (!events || !events.length) {
    return null;
  }

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const {
      events,
      firstName,
      lastName,
      company,
      jobTitle,
      email,
      preferredCity,
      referredBy,
      partners,
    } = form;
    // must select at least 1 event or partner option
    if (events.length + partners.length === 0) {
      formErrorMsg = "Please select at least one event or partnership option";
      return false;
    }
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  return (
    <>
      <section className="request" id="requestForm">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm one">
              <h3>
                To request an invitation to your preferred events, please select
                one or more from the list below:
              </h3>
              {/* <h5>
                Select One or More of the Events You Wish To Attend Below:
              </h5> */}
              <div className="requestForm__row">
                {events
                  .filter((e) => !e.hidden)
                  .map((item, index) => {
                    return (
                      <div className="col-12 col-md-6">
                        <RequestItem
                          itemData={item}
                          key={index}
                          updateForm={updateForm}
                          form={form}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="requestForm two">
              <h3>Your Information:</h3>
              <div className="requestForm__inputs">
                <label className="input__outer sm">
                  <h5>First Name</h5>
                  <div className="input">
                    <input
                      type="name"
                      value={form.firstName}
                      onChange={onChangeInput("firstName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Last Name</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={onChangeInput("lastName")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Company</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.company}
                      onChange={onChangeInput("company")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Job title</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.jobTitle}
                      onChange={onChangeInput("jobTitle")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Email</h5>
                  <div className="input">
                    <input
                      type="email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                  </div>
                </label>
              </div>
            </div>

            <div className="requestForm three">
              <h3>Want to Partner?</h3>
              <div className="requestForm__inputs">
                <label className="input__outer">
                  <h5 className="subheading-text">
                    Let's discuss! We welcome the opportunity to collaborate
                    with partners on a variety of marketing, branding, and
                    business development goals. Let us know if you would be
                    interested in a collaborating as follows (optional):
                  </h5>
                  <label htmlFor="partnerEventSponsorship" className="check">
                    <div className="check__box">
                      <input
                        type="checkbox"
                        id="partnerEventSponsorship"
                        checked={form.partners.some(
                          (p) => p === "partnerEventSponsorship"
                        )}
                        onChange={partnerOnChange}
                      />
                      <span>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/tick.svg"
                          }
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className="check-label">
                      I’d like to discuss a potential Event Sponsorship
                    </div>
                  </label>
                  <label htmlFor="partnerCustomEvent" className="check">
                    <div className="check__box">
                      <input
                        type="checkbox"
                        id="partnerCustomEvent"
                        checked={form.partners.some(
                          (p) => p === "partnerCustomEvent"
                        )}
                        onChange={partnerOnChange}
                      />
                      <span>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/tick.svg"
                          }
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className="check-label">
                      I’d like to discuss a Custom Event
                    </div>
                  </label>
                  <label
                    htmlFor="partnerIndustryTrendsBriefing"
                    className="check"
                  >
                    <div className="check__box">
                      <input
                        type="checkbox"
                        id="partnerIndustryTrendsBriefing"
                        checked={form.partners.some(
                          (p) => p === "partnerIndustryTrendsBriefing"
                        )}
                        onChange={partnerOnChange}
                      />
                      <span>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/tick.svg"
                          }
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className="check-label">
                      I’d like to schedule an Industry Trends Briefing
                    </div>
                  </label>
                </label>
              </div>
            </div>
          </div>
          <div className="requestForm four">
            <div className="requestForm__inputs">
              <label className="input__outer">
                <h5>
                  Any aditional information we should know about you (optional):
                </h5>
                <div className="input">
                  <input
                    type="text"
                    value={form.preferredCity}
                    onChange={onChangeInput("preferredCity")}
                  />
                </div>
              </label>
              <label className="input__outer">
                <h5>Referred By (optional):</h5>
                <div className="input">
                  <input
                    type="text"
                    value={form.referredBy}
                    onChange={onChangeInput("referredBy")}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="requestForm submit">
            {!isShown && (
              <div className="submit-wrapper">
                <button
                  type="submit"
                  onClick={submitForm}
                  className={`button submit-btn ${submitDisabledClass}`}
                >
                  Submit
                </button>
                {formErrorMsg.length ? (
                  <div className="form-invalid-msg">{formErrorMsg}</div>
                ) : null}
              </div>
            )}
          </div>
          <AnimatePresence>
            {/* {isShown && <SuccessModal setIsShown={setIsShown} />} */}
            {isShown && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="submit-success-msg">
                  Thank you. We are excited to welcome you at our various events
                  this year!<br></br>We will be in touch via email regarding
                  invitation details.
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </section>
    </>
  );
}
const RequestItem = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.events];
      arr.push(props.itemData);
      props.updateForm({ events: arr });
    } else {
      props.updateForm({
        events: props.form.events.filter(
          (item) => item.id !== props.itemData.id
        ),
      });
    }
  };

  let checkboxMode = true;

  const randomId = randomString(6);

  const selectedClass = checked ? "selected" : "";

  if (checkboxMode) {
    return (
      <div className="sis-omni industrial-tech w-100">
        <div className="req-item">
          <div className={`req-item-content alt ${selectedClass}`}>
            <div className="req-item-upper">
              <div className="check__box adj">
                <input
                  type="checkbox"
                  id={randomId}
                  checked={checked}
                  onChange={onChange}
                />
                <span>
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                    alt="icon"
                  />
                </span>
              </div>
              <div className="req-item-img">
                <img className="img-fluid" src={props.itemData.imageUrl} />
              </div>
              <div className="req-item-header">
                <h5>{props.itemData.title}</h5>
                <p className="sm">
                  {getDisplayDateFromStartEndDateString(
                    props.itemData.startDate,
                    props.itemData.endDate,
                    props.itemData.endDateEpoch
                  )}
                </p>
                <p className="sm">{props.itemData.location}</p>
              </div>
            </div>
            {/* <div className="req-item-lower">
        </div> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="requestFormItem">
      <div className="requestFormItem__inner">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="requestFormItem__content">
          <div className="d-flex event-container">
            <div className="event-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="event-info">
              <h5>{props.itemData.title}</h5>
              <p className="sm">
                {getDisplayDateFromStartEndDateString(
                  props.itemData.startDate,
                  props.itemData.endDate,
                  props.itemData.endDateEpoch
                )}
              </p>
              <p className="sm">{props.itemData.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
