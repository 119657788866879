import React from "react";

export default function Copy() {
  return (
    <section className="copy big">
      <div className="auto__container">
        <div className="copy__inner">
          <p>
            <div className="footer__inner-copy">
              <div className="footer__inner-copy-links">
                <a href="https://landmarkventures.com/terms-of-use/">
                  Terms of use
                </a>
                <hr />
                <a href="https://landmarkventures.com/privacy-policy/">
                  Privacy Policy
                </a>
              </div>
              <p className="sm">
                © 2022 Landmark Ventures. All Rights Reserved.
              </p>
            </div>
          </p>
        </div>
      </div>
    </section>
  );
}
