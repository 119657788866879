import React from "react";

export default function Partner2() {
  return (
    <section className="partners big">
    <div className="auto__container">
        <div className="partners__inner">
            <p>
                Trusted by the world’s most innovative businesses – big and small
            </p>
            <div className="partners__inner-row">
                <div className="partnersItem">
                    <img className="sm" src="./images/lead-gen/partners/1.png" alt=""/>
                </div>
                <div className="partnersItem">
                    <img src="./images/lead-gen/partners/2.png" alt=""/>
                </div>
                <div className="partnersItem">
                    <img src="./images/lead-gen/partners/3.png" alt=""/>
                </div>
                <div className="partnersItem">
                    <img src="./images/lead-gen/partners/4.png" alt=""/>
                </div>
                <div className="partnersItem">
                    <img className="big" src="./images/lead-gen/partners/5.png" alt=""/>
                </div>
                <div className="partnersItem">
                    <img className="big" src="./images/lead-gen/partners/6.png" alt=""/>
                </div>
            </div>
        </div>
    </div>
</section>
  );
}
