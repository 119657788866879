import React from "react";

export default function Intro() {
  return (
    <section className="hero">
      <div className="hero__bg">
        <img src={process.env.PUBLIC_URL + "/images/hero.jpg"} alt="hero" />
      </div>
      <div className="auto__container">
        <div className="hero__inner">
          <div className="container">
            <div className="row align-items-start align-items-lg-center">
              <div className="col-md-7 offset-xxl-1 col-xxl-6 z2">
                <article className="card-leadspace">
                  <div
                    data-aos="fade-up"
                    data-aos-delay={400}
                    className="aos-init aos-animate"
                  >
                    <h1>
                      <span className="text-white">
                        Join Landmark{" "}
                        <span className="span-leadspace-home__spanner">
                          Ventures 2024 Events
                        </span>
                      </span>
                    </h1>
                    <p>
                      <span className="text-light">
                        Ranging from large summits to highly curated dinners and
                        roundtables, you can count on Landmark Ventures to host
                        a variety of events and experiences throughout the
                        calendar year. Please use the form below to indicate
                        your interest to attend, partner, or host with us!
                      </span>
                    </p>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay={800}
                    className="aos-init aos-animate"
                  >
                    <a
                      href="#requestForm"
                      type="button"
                      className="button btn-std"
                    >
                      Sign Up NOW!
                    </a>
                  </div>{" "}
                </article>
              </div>
              <div className="col-md-5 col-xxl-5">
                <div className="bodymoving mx-auto mt-6 mt-md-0 ms-md-11 circle">
                  <div className="hero-circle">
                    <img
                      src={process.env.PUBLIC_URL + "/images/circle.png"}
                      alt="circle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
