import React from "react";

export default function Offer() {
  return (
    <section className="offer big">
      <div className="auto__container">
        <div className="offer__inner">
          <div className="offer__inner-bg">
            <img src="./images/lead-gen/map.png" alt="" />
          </div>
          <div className="offer__inner-title">
            <h2>
              Register Your Interest Now! Don’t Miss <br />
              The Special Offer <span> 20% Discount</span>
            </h2>
            <p className="big">
              We develop lasting connections in select technology sectors to
              catalyze growth and close deals.
            </p>
            <a href="#" className="button primary">
              Sign Up NOW
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
