import React from "react";
import Slider from "react-slick";
const reviewList = [
  {
    id: "1",
    name: "Mark Smith",
    avatar: "/images/lead-gen/avatar.png",
    job: "Envato Inc.",
    text: `"Landmark organised a remarkable event for our company and delivered above and beyond our expectations."`,
  },
  {
    id: "2",
    name: "Vera Duncan",
    avatar: "/images/lead-gen/avatar.png",
    job: "PayPal Inc.",
    text: `"Landmark organised a remarkable event for our company and delivered above and beyond our expectations."`,
  },
  {
    id: "3",
    name: "Bryce Vaughn",
    avatar: "/images/lead-gen/avatar.png",
    job: "Unbounce Inc..",
    text: `"Landmark organised a remarkable event for our company and delivered above and beyond our expectations."`,
  },
];
export default function Review() {
  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 701,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 541,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="review">
      <div className="auto__container">
        <div className="review__inner">
          <div className="review__inner-title">
            <h3>What do our clients say?</h3>
            <p>
              We deliver a hands-on approach designed to meet each client’s
              needs and ultimately drive a successful strategic process.
            </p>
          </div>
          <Slider {...settings}>
            {reviewList.map((item, index) => {
              return <ReviewItem key={index} {...item} />;
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}
const ReviewItem = (props) => {
  return (
    <div className="reviewItem">
      <div className="reviewItem__inner">
        <div className="reviewItem__avatar">
          <img src={process.env.PUBLIC_URL + props.avatar} alt="" />
        </div>
        <h6>{props.name}</h6>
        <p className="sm">{props.job}</p>
        <p>{props.text}</p>
        <div className="reviewItem__stars">
          <img src="./images/lead-gen/stars.png" alt="" />
        </div>
      </div>
    </div>
  );
};
