import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const getDisplayDateFromStartEndDateString = (
  startDate,
  endDate,
  endDateEpoch = null
) => {
  // Check if startDate contains "TBD" or "TBA"
  if (startDate.includes("TBD") || startDate.includes("TBA")) {
    return startDate;
  }

  const startMoment = moment(startDate, "MMMM D");
  const endMoment = moment(endDate, "MMMM D");

  // Check if endDate is not valid
  if (!endMoment.isValid()) {
    return startDate;
  }

  const startMonth = startMoment.format("MMMM");
  const endMonth = endMoment.format("MMMM");

  // use epoch to determine year
  let yearPostfix = ``;
  if (endDateEpoch) {
    const date = new Date(endDateEpoch * 1000);
    const year = date.getFullYear();
    const multipleSpaces = "\u00A0\u00A0"; // Non-breaking space repeated multiple times
    yearPostfix = `${multipleSpaces}${year}`;
  }

  if (startMonth === endMonth) {
    // Same month
    const dateString = `${startMonth} ${startMoment.format(
      "D"
    )} - ${endMoment.format("D")},${yearPostfix}`;
    return dateString;
  } else {
    // Different months
    const dateString = `${startMonth} ${startMoment.format(
      "D"
    )} - ${endMonth} ${endMoment.format("D")},${yearPostfix}`;
    return dateString;
  }
};

export const epochToDate = (epochTime, options = { removeDay: false }) => {
  const date = new Date(epochTime * 1000); // convert seconds to milliseconds
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  if (options.removeDay) {
    return `${month} ${year}`;
  }
  const day = date.getDate();
  // add the appropriate ordinal suffix to the day (e.g. "1st", "2nd", "3rd")
  let dayStr;
  if (day % 10 === 1 && day !== 11) {
    dayStr = day + "st";
  } else if (day % 10 === 2 && day !== 12) {
    dayStr = day + "nd";
  } else if (day % 10 === 3 && day !== 13) {
    dayStr = day + "rd";
  } else {
    dayStr = day + "th";
  }
  return `${dayStr} ${month} ${year}`;
};

export const dateToYYYYMMDD = (date) => {
  if (typeof date === "string") {
    date = new Date(date); // Convert string to Date object
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

export const randomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const useScrollToLocation = () => {
  const scrolledRef = React.useRef(false);
  const { hash } = useLocation();
  const hashRef = React.useRef(hash);

  React.useEffect(() => {
    if (hash) {
      // We want to reset if the hash has changed
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
      if (!scrolledRef.current) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
          // const elementTop =
          //   element.getBoundingClientRect().top + window.scrollY;
          // window.scrollTo({
          //   top: elementTop,
          //   behavior: "smooth",
          // });
          scrolledRef.current = true;
        }
      }
    }
  });
};

// Tuesday, January 10, 2024 --> Tuesday, January 10th
export const formatDatestring = (str, { shortDay = false }) => {
  let [day, date, year] = str.split(",");
  const dayOfMonth = date.split(" ")[1];
  const postfix = getNumPostfix(dayOfMonth);
  if (shortDay) {
    const dayMap = {
      Sunday: "Sun",
      Monday: "Mon",
      Tuesday: "Tues",
      Wednesday: "Wed",
      Thursday: "Thurs",
      Friday: "Fri",
      Saturday: "Sat",
    };
    day = dayMap[day.trim()];
  }
  return `${day}, ${date}${postfix}`;
};

export const getNumPostfix = (number) => {
  // Convert the number to a string to access its last digit
  const numStr = number.toString();

  // Check for special cases: 11th, 12th, and 13th
  if (numStr.length >= 2 && numStr.slice(-2) === "11") {
    return "th";
  } else if (numStr.length >= 2 && numStr.slice(-2) === "12") {
    return "th";
  } else if (numStr.length >= 2 && numStr.slice(-2) === "13") {
    return "th";
  }

  // For other numbers, determine the postfix based on the last digit
  const lastDigit = numStr.slice(-1);

  switch (lastDigit) {
    case "1":
      return "st";
    case "2":
      return "nd";
    case "3":
      return "rd";
    default:
      return "th";
  }
};
