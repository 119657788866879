import React from "react";
import Slider from "react-slick";
const events = [];

// 14 image files in /images/events folder (manually update if changed)
for (let i = 1; i <= 14; i++) {
  events.push({
    id: `${i}`,
    image: `/images/events/${i}.jpg`,
  });
}

export default function Event() {
  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 4500,
    cssEase: "linear",
    autoplaySpeed: 0,
    responsive: [
      {
        breakpoint: 931,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 541,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="events">
      <div className="auto__container">
        <div className="events__inner">
          <div className="events__inner-slider">
            <Slider {...settings}>
              {events.map((item, index) => {
                return (
                  <div className="eventsItem" key={index}>
                    <div className="eventsItem__inner">
                      <img
                        src={process.env.PUBLIC_URL + item.image}
                        alt="event"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
