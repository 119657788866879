import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Intro from "./sections/Intro";
import Join from "./sections/Join";
import Company from "./sections/Company";
import Request from "./sections/Request";
import Event from "./sections/Event";
import Partner from "./sections/Partner";
import Head from "../Base/Head";

export default function CES() {
  const head = {
    title: "CES 2024 - Landmark Ventures",
    description:
      "We are pleased to host our annual LMV @ CES event series, custom built for our robust network of Fortune 500 CMOs, Chief Digital Officer, Venture Capitalists, Entrepreneurs, and Brand Executives across the digital marketing, eCommerce, advertising, sports, social impact, Web3, and media ecosystems.",
    url: "https://ces.landmarkventures.com",
    image: "https://i.imgur.com/tbAYd6H.jpg",
  };

  return (
    <>
      <Head head={head} />
      <div className="events-wrapper ces-wrapper redesign">
        <Intro />
        {/* <Join /> */}
        {/* <Event /> */}
        <Request />
        {/* <Company /> */}
        {/* <Partner /> */}
      </div>
    </>
  );
}
