import React, { useEffect } from "react";
import Head from "../Base/Head";
import Market from "./sections/Market";
import Partner2 from "./sections/Partner2";
import Banner from "./sections/Banner";
import Review from "./sections/Review";
import Offer from "./sections/Offer";
import Copy from "./sections/Copy";

export default function LeadGen({ setLeadPage }) {
  const head = {
    title: "Lead GeneraFtion",
    description: "Description",
    url: "URL_HERE",
    image: "URL_HERE",
  };
  useEffect(() => {
    setLeadPage(true);
  }, []);


  return (
    <> 
      <div className="lead-gen-wrapper">
        <Market />
        <Partner2 />
        <Banner />
        <Review />
        <Offer />
        <Copy />
      </div>
    </>
  );
}
