import React from "react";
import { Helmet } from "react-helmet";

export default function Head({ head }) {
  return (
    <Helmet>
      <title>{head.title}</title>
      <meta name="description" content={head.description} />
      <meta
        name="keywords"
        content="landmark ventures events industrial technology portfolio company"
      />
      <meta name="author" content="LandmarkVentures" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={head.url} />
      <meta property="og:title" content={head.title} />
      <meta property="og:description" content={head.description} />
      <meta property="og:image" content={head.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={head.title} />
      <meta name="twitter:site" content="@Landmark" />
      <meta name="twitter:description" content={head.description} />
      <meta name="twitter:image" content={head.image} />
      <meta name="twitter:image:alt" content={head.title} />
    </Helmet>
  );
}
