import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Intro from "./sections/Intro";
import Join from "./sections/Join";
import Company from "./sections/Company";
import Request from "./sections/Request";
import Event from "./sections/Event";
import Partner from "./sections/Partner";
import Head from "../Base/Head";

export default function Events() {
  const head = {
    title: "Events - Landmark Ventures",
    description:
      "Ranging from large summits to highly curated dinners and roundtables, you can count on Landmark Ventures to host a variety of events and experiences throughout the calendar year.",
    url: "https://events.landmarkventures.com",
    image: "https://i.imgur.com/IXZrPmk.jpg",
  };

  return (
    <>
      <Head head={head} />
      <div className="events-wrapper">
        <Intro />
        {/* <Join /> */}
        <Event />
        <Request />
        <Company />
        {/* <Partner /> */}
      </div>
    </>
  );
}
